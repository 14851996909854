/* General Container */
.container-fluid {
  width: 100%; /* Ensure full width on all devices */
  padding: 15px; /* Ensure some padding around the container */
}

.digital {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

/* Flexbox Layout for Responsiveness */
.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* For Video Containers */
.video-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.video-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 1024px) {
  .video-container {
    max-width: 700px; /* Slightly reduce for medium screens */
  }
}

@media (max-width: 768px) {
  .video-container {
    max-width: 100%; /* Full width for smaller devices */
  }
}

.image-container {
  background-image: url("/src/images copy/digitalbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 অনুপাতের জন্য (9/16 = 0.5625) */
  position: relative;
  overflow: hidden;
}

.text-content {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: yellow;
  text-align: right;
  max-width: 40%;
  box-sizing: border-box;
  overflow-y: auto;
}

/* small device */
@media (max-width: 768px) {
  .image-container {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
  }
  .text-content {
    width: 90%;
    max-height: 90%;
    top: 50%;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
